var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('dashboard-page-title',{staticClass:"w-100",attrs:{"showAddBtn":false},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"d-flex gap-2"},[_c('b-button',{staticClass:"px-5 mt-2 ml-2 mr-2 mb-0",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'addMerchant' })}}},[_vm._v(" إضافة ")]),_c('v-select',{staticClass:"m-md-2 actionButton",attrs:{"options":[
              { label: 'مكتمل', value: 'completed' },
              { label: 'قيد المراجعة', value: 'processing' },
              { label: 'معلق', value: 'pending' },
              // pending, processing, publish, unpublish, completed
            ],"clearable":false,"reduce":(item) => item.value},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]},proxy:true}])},[_vm._v("المتاجر ")])],1),_c('main-table',{attrs:{"baseURL":_vm.SFZ_API_LINK,"fields":_vm.fields,"list_url":"admin/stores","params":{
      status: _vm.selected,
    },"reloadTable":_vm.reloadTable}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }