<template>
  <div>
    <div class="d-flex w-100 justify-content-between">
      <dashboard-page-title :showAddBtn="false" class="w-100"
        >المتاجر
        <template #left>
          <div class="d-flex gap-2">
            <b-button
              @click.prevent="$router.push({ name: 'addMerchant' })"
              variant="primary"
              class="px-5 mt-2 ml-2 mr-2 mb-0"
            >
              إضافة
            </b-button>
            <v-select
              v-model="selected"
              :options="[
                { label: 'مكتمل', value: 'completed' },
                { label: 'قيد المراجعة', value: 'processing' },
                { label: 'معلق', value: 'pending' },
                // pending, processing, publish, unpublish, completed
              ]"
              class="m-md-2 actionButton"
              :clearable="false"
              :reduce="(item) => item.value"
            />
          </div>
        </template>
      </dashboard-page-title>
    </div>
    <main-table
      :baseURL="SFZ_API_LINK"
      :fields="fields"
      list_url="admin/stores"
      :params="{
        status: selected,
      }"
      :reloadTable="reloadTable"
    ></main-table>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { SFZ_API_LINK } from "@/config/constant";
import marketPlaceServices from "../services/marketPlace";

export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      SFZ_API_LINK,
      selected: "completed",
      fields: [
        {
          label: "صوره الشعار",
          key: "logo",
          type: "image",
          class: "text-right",
        },
        {
          label: "صوره الغلاف",
          key: "cover",
          type: "image",
          class: "text-right",
        },
        { label: "الاسم", key: "name", clacompletedss: "text-right" },
        {
          label: "الحالة",
          key: "status",
          class: "text-right",
          type: "format",
          format: (value) => {
            if (value.status === "publish") {
              return "مكتمل";
            } else if (value.status === "in_progress") {
              return "قيد المراجعة";
            } else if (value.status === "pending") {
              return "معلق";
            }
          },
        },
        {
          label: "التاريخ",
          key: "created_at",
          class: "text-right",
          type: "date",
        },
        {
          label: "التحكم",
          key: "actions",
          class: "text-right",
          type: "actions",
          actions: [
            {
              text: "Delete",
              icon: "ri-delete-bin-line",
              color: "danger",
              url: "admin/stores",
              titleHeader: "متجر",
              question: "هل متأكد أنك تريد مسح هذا المتجر",
              textContent: "name",
              textDeleteButton: "بالتأكيد, مسح",
              textCancelButton: "الغاء",
              showAlert: true,
              baseUrl: SFZ_API_LINK,
            },
            {
              icon: "las la-check",
              color: "success-light",
              text: "تفعيل",
              actionName: "activeMerchent",
              actionParams: ["id"],
              className: "d-flex justify-content-center align-items-center",
              showIf: (dataItem) => dataItem.status === "processing",
            },
            {
              icon: "ri-eye-line ",
              color: "primary-light",
              text: "عرض",
              ifNavigate: true,
              routeParams: ["id"],
              routeName: "viewMerchant",
            },
          ],
        },
      ],
      reloadTable: false,
    };
  },
  methods: {
    activeMerchent(obj) {
      console.log(obj);
      marketPlaceServices
        .acceptMerchent(obj.id, { status: "completed" })
        .then((res) => {
          core.showSnackbar("success", "تم القبول");
          this.reloadTable = true;
        });
    },
  },
  created() {
    this.$root.$on("activeMerchent", this.activeMerchent);
  },
  beforeDestroy() {
    this.$root.$off("activeMerchent");
  },
};
</script>
